import React, { useState, useEffect, lazy } from "react";
import axios from "axios";
import { Slider, Statistic, Tabs, Modal,Result } from "antd";
import {
  ArrowUpOutlined,
  MinusCircleFilled,
  ToolOutlined,
  ArrowDownOutlined,
  FileFilled,
  WalletFilled,
  SearchOutlined,
  FundViewOutlined,
  UserOutlined,
  AccountBookOutlined,
  CloseOutlined,
  CalculatorFilled,
  SwapOutlined,
  GroupOutlined,
  AccountBookFilled,
  DollarCircleOutlined,
  EditFilled,
  MessageFilled,
  DollarOutlined,
  CarFilled,
  LoadingOutlined,
  PlusCircleFilled,
  DeleteOutlined,
  PhoneFilled,
  RedoOutlined

} from "@ant-design/icons";
import moment from "moment";
import { Line, Pie, Bar } from "react-chartjs-2";
import { withGetScreen } from "react-getscreen";
import {
  Tooltip,
  Popover,
  Table,
  Popconfirm,
  Input,
  Image,
  Button,
  Collapse,
  Card,
  Avatar,
  Form,
  DatePicker,
  Select,
  Space,
  Typography,
  Divider,
  InputNumber,
  message,
  Spin,
} from "antd";
import { Col, Row } from "reactstrap";
import { CustomTooltips } from "@coreui/coreui-plugin-chartjs-custom-tooltips";
import { getStyle, hexToRgba } from "@coreui/coreui/dist/js/coreui-utilities";
import * as serverconfig from "./serverconn";
import {
  MdEmail,
  MdHome,
  MdMailOutline,
  MdMap,
  MdMenu,
  MdPhone,
  MdPhoneAndroid,
  MdShoppingCart,
} from "react-icons/md";

import CurrencyFormat from "react-currency-format";
import Highlighter from "react-highlight-words";
import Websocket from "react-websocket";
import { Link } from "react-router-dom";
import ReconnectingWebSocket from "reconnecting-websocket";
import { NavLink } from "react-router-dom";
import * as primarycolor from "./primarycolor";
import logo from "../assets/eatslogo.png";
import RestaurantsMenuDetails from "./RestaurantsMenuDetails";
import * as reactstrp from 'reactstrap';
import GoogleLogin from "./ GoogleLogin";
import MyContext from './MyContext';

import eats_phone_logo from "../assets/eats_phone_logo.png";
import eats_map_logo from "../assets/eats_map_logo.png";

import def_menu_logo from "../assets/default_menu.png";
import './SlideBar.css'; // Import CSS file for styling


var CryptoJS = require("crypto-js");

const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

const style = {
  background: "#0092ff",
  padding: "8px 0",
  borderRadius: "7px",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
};
const dateFormat = "DD/MM/YYYY";
const yeardateFormat = "YYYY";
const monthdateFormat = "MM/YYYY";

const { Meta } = Card;
const { Panel } = Collapse;
const { TabPane } = Tabs;
const FormItem = Form.Item;
const { Option } = Select;
const { Search } = Input;

var bizuserid = "";
var sacco = "";
var token = "";
const t_time="HH:mm:ss"


class RestaurantHome extends React.Component {
  state = {
    datarequested: true,
    restaurant_obj: {},
    restaurant_menus: [],
    restaurant_menus_placeholder: [],

    menu_details_visible: false,
    selected_menu_object: {},

    current_cart_list: [],
    current_tab:'',

    //now proceed to upload section
    mode_of_payment:'',
    additional_addressinfo:'',
    notes:'',
    areas:[],
    area:'',
    delivery_price:0,
    hub_zones:[],
    telephone_contact:'',
    datasubmittedsuccessfully:false,
    promos:[],
    dish_id_toread_more:'',
    read_more:false
    
  };

  callback = (key) => {
    console.log(key);
    console.log(this.state.yearlymonthlysales);
  };

  componentDidMount() {
    this.comp_loaded()
  }


    //capitalize fistrt letter
    capitalizeFirstLetter=(word)=> {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }
  
  
    capitalizeSentence=(sentence)=> {
      return sentence.split(' ').map(word =>
        word.charAt(0).toUpperCase() + word.slice(1)
      ).join(' ');
    }


  comp_loaded=()=>{

    const { setshowtop_items } = this.context;
    // Update the context value
    setshowtop_items(false);



     //ned to eplore
      console.log(this.props.restaurant)
      this.setState({restaurant_obj:this.props.restaurant})


     let form_data_1_1 = new FormData();
     axios
      .post(
        serverconfig.backendserverurl + `/customqueries/get_all_areas`,
        form_data_1_1,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        this.setState({
          areas: JSON.parse(res.data.report),
        });

      });


      var res_data = this.props.restaurant
    
      let value = localStorage.getItem("current_cart_list");
      if (value != null) {
        if (res_data.name === JSON.parse(value).restaurant_name) {
          this.setState({
            current_cart_list: JSON.parse(JSON.parse(value).items),
          });
        } else {
          this.setState({ current_cart_list: [] });
        }
      } else {
        this.setState({ current_cart_list: [] });
      }


      //get hub zones
      let form_data_o1 = new FormData();
      form_data_o1.append("restaurant_id", res_data.id);
        axios.post(serverconfig.backendserverurl+'/customqueries/get_restaurant_hubzones',
       form_data_o1, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })

      .then(res => {
        this.setState({
          hub_zones: JSON.parse(res.data.report),
        });

        })
      .catch(error => console.log(error))


    //get res menus
    
    let form_data = new FormData();
    form_data.append("backend", serverconfig.backendserverurl);
    form_data.append("restaurant_name", res_data.name);
    axios
      .post(
        serverconfig.backendserverurl +
          `/customqueries/get_restaurant_menus_byname`,
        form_data,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        //set current tabe
        this.setState({current_tab:JSON.parse(res.data.report).key})

        this.setState({
          restaurant_menus: JSON.parse(res.data.report),
        });

        this.setState({
          restaurant_menus_placeholder: JSON.parse(res.data.report),
        });

        //this.setState({ datarequested: false });
      });



      ///get curent restaurant promos
      let form_data___1 = new FormData();
      form_data___1.append("backend", serverconfig.backendserverurl);
      form_data___1.append("restaurant_id", res_data.id);
      axios
        .post(
          serverconfig.backendserverurl +
            `/customqueries/get_promos_restaurant`,
            form_data___1,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        )
      .then((res) => {

        this.setState({
          promos: JSON.parse(res.data.report),
        });

        this.setState({ datarequested: false });
      });


      //set data
      this.setState({datasubmittedsuccessfully:false})

  }


    //get first fifty words
    getWordsInFirst50Characters=(text)=> {
      // Split the text into words
      const words = text.split(' ');
    
      // Initialize variables to keep track of total characters and words
      let characterCount = 0;
      let wordCount = 0;
      const result = [];
    
      // Iterate through words until the character count reaches 50
      for (const word of words) {
        // Calculate the new character count if we include the next word
        const newCharacterCount = characterCount + word.length;
    
        // If adding the next word exceeds 50 characters, break the loop
        if (newCharacterCount > 30) {
          break;
        }
    
        // Add the word to the result array
        result.push(word);
    
        // Update character count and word count
        characterCount = newCharacterCount;
        wordCount++;
      }
    
      // Join the words in the result array to form the truncated text
      const truncatedText = result.join(' ');
    
      return truncatedText;
    }







    //calculate totla
    calculateTotal=()=>{
      var total=0
      this.state.current_cart_list.map((item)=>{
        total+=Number(item.price)
      })
      
      return total
    }


  render() {


    if (this.state.datarequested === true) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin size="large">
            <div className="content">
              <h3>eats.biz</h3>
            </div>
          </Spin>
        </div>
      );
    } else {


      if(this.state.datasubmittedsuccessfully===true){
        return(
          <div>
              <Card>
              <Result
              status="success"
              title="Order successfully submitted."
              extra={[
                <Button  type='primary' 
                onClick={()=>{
                  this.comp_loaded()
                }}>Finish</Button>,
              ]}
              />
              </Card>
          </div>
        )    

      }else{

        return (
          <div className="animated fadeIn"  >
           
              <div style={{backgroundColor:'black'}}>

                  <div style={{
                  display:'flex',
                  flexDirection:'column',
                  backgroundImage: `url(${this.state.restaurant_obj.restaurant_banner===""?def_menu_logo:this.state.restaurant_obj.restaurant_banner})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  width: '100%',
                  //height: '300px', // Adjust height as needed
                  borderBottomLeftRadius: '60px',
                  borderBottomRightRadius: '60px',
                    
                  }}>
               
                {/**image in banner */}
               
                {
                  this.state.restaurant_obj.logo===""?
                  null:
                  <div style={{display:'flex',height:'75px',
                  width:'75px',margin:25,marginTop:20,
                  backgroundColor:'white',
                  alignItems:'center',
                  justifyContent:'center'}}>
                   <Image
                    src={this.state.restaurant_obj.logo}   
                    preview={false} 
                    width={65}
                    height={65}
   
                   ></Image>
                  </div>
                }

            </div>

              {/** restauant name & Address  */}
              <div style={{display:'flex', flexDirection:'', background:'black', justifyContent: 'space-between', margin:3}}>
              
                <div style={{flexDirection:'column',justifyContent:'flex-start'}}>
                  
                  <p style={{fontSize:14, fontWeight:'bolder', color:'white'}}>
                    &nbsp;
                    &nbsp;

                    {this.state.restaurant_obj.multibranch_name === "" ?
                      this.state.restaurant_obj.name.toUpperCase() :
                      this.state.restaurant_obj.multibranch_name.toUpperCase()}
                  </p>
                  <span style={{marginLeft:4,display:'flex',flexDirection:'row'}}>

                  <Image
                    src={eats_phone_logo}   
                    preview={false} 
                    width={60}
                    height={50}
                    onClick={() => {
                      window.location.href = `tel:${this.state.restaurant_obj.phone}`;
                    }}
                  />
                  <span style={{fontSize:16,color:'#fff',justifyContent:'center',alignContent:'center'}}>{this.state.restaurant_obj.phone}</span>
                  </span>

                </div>

                <div style={{flexDirection:'column', justifyContent:'center', marginLeft:30, alignSelf:'flex-end'}}>
                  <div style={{display:'flex', justifyContent:'flex-end'}}>
                    <Image
                      src={eats_map_logo}   
                      preview={false} 
                      width={50}
                      height={40}
                      onClick={() => {
                        window.location.href = `${this.state.restaurant_obj.map_url}`;
                      }}
                    />                
                  </div>
                  <p style={{display:'flex',fontSize:12, fontWeight:'bold', color:'white',justifyContent:'flex-end'}}>
                    {this.state.restaurant_obj.address}
                    &nbsp;
                    &nbsp;

                  </p>

                  <span style={{display:'flex',fontSize:12, fontWeight:'bold', color:'white',justifyContent:'flex-end'}}>
                    {this.capitalizeSentence(this.state.restaurant_obj.area_name)}
                    &nbsp;
                    &nbsp;

                  </span>

                </div>
              </div>

              </div>


            {/**Search by key word in menus list */}
            <FormItem label="Search">
              <Search
              placeholder="Search"
              onChange={(value) => {
                var val=value.target.value
                console.log(val)
                if (val==='' || val===undefined || val===null){
                  this.setState({restaurant_menus:this.state.restaurant_menus_placeholder});
                }

                if (val !== '' || val !== undefined) {
                  //set one is by NAME
                  const results_1 = this.state.restaurant_menus.filter((request) => {
                    return String(request.category_name).toLowerCase().includes(val.toLowerCase());
                    // Use the toLowerCase() method to make it case-insensitive
                  });
            
                  if (results_1.length === 0) {
                    this.setState({restaurant_menus:this.state.restaurant_menus_placeholder});

                  } else {
                    this.setState({restaurant_menus:results_1});
                    console.log(results_1)
                  }
            
                } else {
                  this.setState({restaurant_menus:this.state.restaurant_menus_placeholder});
                }
            
              }}
              style={{
                width: 200,
                margin:5
              }}
              suffix={<RedoOutlined onClick={()=>{
                this.setState({restaurant_menus:this.state.restaurant_menus_placeholder});

              }} style={{ cursor: 'pointer' }} />}

              />
          </FormItem>            

            {/**End of the manu tabs */}

             {
                  this.state.restaurant_menus.length>0?
                  <Tabs defaultActiveKey={this.state.current_tab}
                    onChange={
                      (val) => {
                        console.log(val)
                        //get current 
                       // this.comp_loaded()
                        this.setState({current_tab:val})
                    }}
                    style={{margin:6}}
                    >
                     


                      {/**General menu */}
                      {
                        this.state.restaurant_menus.map((menu)=>{
                          return (
                           <TabPane
                            tab={
                              <span>
                                {menu.category_name.toUpperCase()}
                              </span>
                            }
                            key={menu.id}
                          >
                              <RestaurantsMenuDetails
                              menu_object={menu}
                              restaurant_name={this.state.restaurant_obj.name}
                              restaurant_obj={this.state.restaurant_obj}
                              />
  
                           </TabPane>
                          )
                        })
                      }


                  </Tabs>
                  :
                  <h3 style={{display:'flex',justifyContent:'center',alignSelf:'center',fontWeight:'bolder'}}>No menu</h3>
                }

            <br></br>
            <br></br>


            {this.state.menu_details_visible === true ? (
              <Modal
                visible={this.state.menu_details_visible}
                title={this.state.selected_menu_object.category_name}
                onCancel={() => {
                  this.setState({ menu_details_visible: false });
                }}
                footer={[
                  <Button
                    key="back"
                    onClick={() => {
                      this.setState({ menu_details_visible: false });
                    }}
                  >
                    Cancel
                  </Button>,
                ]}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignSelf: "center",
                  }}
                >
                  <RestaurantsMenuDetails
                    menu_object={this.state.selected_menu_object}
                    restaurant_name={this.state.restaurant_obj.name}
                  />
                </div>
              </Modal>
            ) : null}
  
          </div>
        );

      }

    }
  }
}

RestaurantHome.contextType = MyContext;

export default RestaurantHome;
