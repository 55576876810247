import React, { useState, useEffect, lazy,useContext } from "react";

import axios from "axios";
import { Slider, Statistic, Tabs, Modal } from "antd";
import {
  ArrowUpOutlined,
  MinusCircleFilled,
  ToolOutlined,
  ArrowDownOutlined,
  FileFilled,
  WalletFilled,
  SearchOutlined,
  FundViewOutlined,
  UserOutlined,
  AccountBookOutlined,
  CloseOutlined,
  CalculatorFilled,
  SwapOutlined,
  GroupOutlined,
  AccountBookFilled,
  DollarCircleOutlined,
  EditFilled,
  MessageFilled,
  DollarOutlined,
  CarFilled,
  LoadingOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import moment from "moment";
import { Line, Pie, Bar } from "react-chartjs-2";
import { withGetScreen } from "react-getscreen";
import {
  Tooltip,
  Popover,
  Table,
  Popconfirm,
  Input,
  Image,
  Button,
  Collapse,
  Card,
  Avatar,
  Form,
  DatePicker,
  Select,
  Space,
  Typography,
  Divider,
  InputNumber,
  message,
  Spin,
} from "antd";
import { Col, Row } from "reactstrap";
import { CustomTooltips } from "@coreui/coreui-plugin-chartjs-custom-tooltips";
import { getStyle, hexToRgba } from "@coreui/coreui/dist/js/coreui-utilities";
import * as serverconfig from "./serverconn";
import { MdMailOutline, MdPhone, MdShoppingCart } from "react-icons/md";

import CurrencyFormat from "react-currency-format";
import Highlighter from "react-highlight-words";
import Websocket from "react-websocket";
import { Link } from "react-router-dom";
import ReconnectingWebSocket from "reconnecting-websocket";
import { NavLink } from "react-router-dom";
import * as primarycolor from "./primarycolor";
import logo from "../assets/placeholder.png";
//import GoogleLogin from "./ GoogleLogin";
import SocialLogin from "./ SocialLogin";
//import { GoogleLogin } from '@react-oauth/google';
import GoogleLogin from "./ GoogleLogin";
import RestaurantHome from "./RestaurantsHome";
import MyContext from './MyContext';


var CryptoJS = require("crypto-js");

const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

const style = {
  background: "#0092ff",
  padding: "8px 0",
  borderRadius: "7px",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
};
const dateFormat = "DD/MM/YYYY";
const yeardateFormat = "YYYY";
const monthdateFormat = "MM/YYYY";

const { Meta } = Card;
const { Panel } = Collapse;
const { TabPane } = Tabs;
const FormItem = Form.Item;
const { Option } = Select;
const { Search } = Input;

var bizuserid = "";
var sacco = "";
var token = "";

const Promotions = (props) => {
  const context = useContext(MyContext);

  const [datarequested, setdatarequested] = useState(true);
  const [restaurants, setrestaurants] = useState([]);
  const [restaurants_placeholders, setrestaurants_placeholders] = useState([]);
  const [contine_withgoogle, setcontine_withgoogle] = useState(false);
  const [selected_record, setselected_record] = useState({});
  const [user_profile, setuser_profile] = useState({});
  const [promotions, setpromotions] = useState([]);
  const [isMobile, setIsMobile] = useState(false);


  // Function to check if the screen size is mobile
  const checkIsMobile = () => {
    setIsMobile(window.innerWidth < 768); // Adjust breakpoint as needed
  };


  useEffect(() => {

    // Check initial screen size
    checkIsMobile();

    //compenent 
    component_loaded()


    // Add event listener for screen resize
    window.addEventListener('resize', checkIsMobile);

    // Cleanup
    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);



 const  component_loaded=()=>{

  const { setshowtop_items } = context;
  // Update the context value
  setshowtop_items(true);

    let form_data_1 = new FormData();
    form_data_1.append("backend", serverconfig.backendserverurl);
    axios
      .post(
        serverconfig.backendserverurl + `/customqueries/get_promos`,
        form_data_1,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((res) => {

        setpromotions(JSON.parse(res.data.report))

       // this.setState({ datarequested: false });
      });



    let form_data = new FormData();
    form_data.append("backend", serverconfig.backendserverurl);
    axios
      .post(
        serverconfig.backendserverurl + `/customqueries/get_allrestaurants`,
        form_data,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((res) => {

        const uniqueItems = filterDuplicates(JSON.parse(res.data.report));

        setrestaurants(uniqueItems)
        setrestaurants_placeholders(uniqueItems)

        setdatarequested(false)

      });

    
  }


  //capitalize fistrt letter
 const capitalizeFirstLetter=(word)=> {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }



  const capitalizeSentence=(sentence)=> {
    return sentence.split(' ').map(word =>
      word.charAt(0).toUpperCase() + word.slice(1)
    ).join(' ');
  }
  

  // Function to filter out duplicates based on the name key
   const  filterDuplicates = (list) => {
      const uniqueNames = new Set();
      const result = [];
      list.forEach(item => {
        if (!uniqueNames.has(item.multibranch_name) || item.multibranch_name === "") {
          uniqueNames.add(item.multibranch_name);
          result.push(item);
        }
      });
      return result;
    }
    
  
    if (datarequested === true) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin size="large">
            <div className="content">
              <h3>eats.biz</h3>
            </div>
          </Spin>
        </div>
      );
    } else {
      return (
        <div  style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection:'column'
        }}>
       
       
         <div style={{display:'flex',
            justifyContent:'center',
            alignItems:'center',
            alignSelf:'center',
            margin:'auto',
            flexDirection:'column'
         }}>
         <h4 style={{display:'flex',justifyContent:'center',color:'black',alignSelf:'center',alignContent:'center',fontWeight:'bold'}}>
          All Promos 
          </h4>
                   
         </div>
          

          <div style={{ display: 'flex', 
            justifyContent: 'center',
            alignContent:'center',
            alignItems:'center',
            alignSelf: 'center',
            flexDirection: 'row',
            flexWrap: 'wrap',
            width:'100%'
             }}>

            {

              promotions.map((item)=>{
                  return (

                    <span style={{display:'flex',flexDirection:'column',height:isMobile?200:300,width:isMobile?'30%':300}}>
                    <Link to={`/${item.online_order_link}`} style={{display:'flex',flexDirection:'column',height:'100%',width:'100%'}}>
                    
                      <span style={{display:'flex',
                        height:"70%",
                        width: '100%',
                        backgroundColor:'white',
                        alignItems:'center',
                        justifyContent:'center',
                        borderRadius: '5px',

                        }}>

                      <span 
                      style={{
                        height:"100%",
                        display:'flex',
                        flexDirection:'column',
                        backgroundImage: `url(${item.image === "" ? logo:item.image})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        width: '98%',
                        //height: '300px', // Adjust height as needed
                        justifyContent:'flex-end',
                        alignItems:'flex-end',
                        borderRadius: '5px',
                      }}>

                       <span
                        style={{
                          color:'white',
                          backgroundColor:'black',
                          margin:2,
                          padding:3
                        }}
                        >
                          {item.head}
                        </span>

                      </span>
                      </span>
                      <span style={{justifyContent:'center',alignItems:'center',alignSelf:'center',margin:10,color:'black',fontWeight:'bolder',fontSize:17}}> 
                      {item.restaurant}
                      </span>

                      </Link>

                      <div style={{display:'flex',flexDirection:'column',width:'100%',justifyContent:'center',margin:5,alignItems:'center'}}>

                      <Link to={`/${item.online_order_link}`}>
                        <Button type='primary'>View Promo</Button>
                      </Link>
                      
                      </div>
                    </span>

                  )

              })
            }
          </div>

        </div>
      );
    }
 
}


export default Promotions;
