import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createStore, compose,applyMiddleware} from 'redux'
import {Provider} from 'react-redux'
import thunk from 'redux-thunk'
import reducer from './store/reducers/auth';
//import 'bootstrap/dist/css/bootstrap.css';

const composeEnhances= window._REDUX_DEVTOOLS_EXTENSION_COMPOSE_ || compose

const store = createStore(reducer, composeEnhances(
  applyMiddleware(thunk)

))

const app=(
  <Provider store={store}>
     <App />
  </Provider>
)

ReactDOM.render(app,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.register();