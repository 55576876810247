import React, { useState, useEffect, lazy,useContext } from "react";
import { connect } from 'react-redux'
import { Form, Input, Button,Spin,Card,Avatar,Anchor,BackTop,Affix,notification } from 'antd';
import { UserOutlined,DropboxOutlined,LockOutlined,MessageFilled, LoadingOutlined,RightCircleFilled, PhoneFilled, PhoneOutlined, MessageOutlined, MailOutlined, BankOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom'
import * as actions from '../store/actions/auth'
import { withRouter } from 'react-router-dom';
import axios from 'axios'
import * as serverconfig from './serverconn'
import { Layout, Menu, Breadcrumb,Carousel,Tabs,Collapse,Image,Drawer } from 'antd';
import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import logo from '../assets/eatslogo.png';
import About from './About'
import Restaurants from './Restaurants'
import ContactUsForm from './ContactUsForm'

import './tablestyle.css'; // You can define your CSS styles here
import { MdHome, MdList, MdShoppingCart } from 'react-icons/md';
import { MenuOutlined } from '@ant-design/icons';
import MyContext from './MyContext';

var CryptoJS = require("crypto-js");

const { Header, Content, Footer } = Layout;

const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { TabPane } = Tabs;
const { Panel } = Collapse;
const { Meta } = Card;


const NormalLoginForm = () => {
  const context = useContext(MyContext);


  const [isMobile, setIsMobile] = useState(false);
  const [datarequested, setdatarequested] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  // Function to check if the screen size is mobile
  const checkIsMobile = () => {
    setIsMobile(window.innerWidth < 768); // Adjust breakpoint as needed
  };

  useEffect(() => {
    // Check initial screen size
    checkIsMobile();

    //compenent 
    component_loaded()


    // Add event listener for screen resize
    window.addEventListener('resize', checkIsMobile);

    // Cleanup
    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);



  const  component_loaded=()=>{

    const { setshowtop_items } = context;
    // Update the context value
    setshowtop_items(true);
  
  
    }


    if(datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
            <Spin size="large">
            <div className="content">
              <h3>eats.biz</h3>
            </div>
            </Spin> 
       </div>
      )
    }else{

      return (
        <div className="animated fadeIn">
          <Restaurants />
       </div>
       );
    }

}



export default withRouter (NormalLoginForm);
