import React, { useState, useEffect } from 'react';
//import { HashRouter, Route, Switch } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
//import 'antd/dist/antd.css'; 
import 'antd/dist/result.css';

import { connect } from 'react-redux'
import * as actions from './store/actions/auth'
//import { BrowserRouter as Router } from 'react-router-dom'

import LoginLayout from './views/LoginLayout'
// Containers
import LoginBaseRouter from './loginroutes'
import * as serverconfig from "./views/serverconn";
import axios from "axios";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Login from './views/Login';
import Restaurants from './views/Restaurants';
import ContactUsForm from './views/ContactUsForm';
import RestaurantHome from './views/RestaurantsHome';
import RestaurantsHome_Multibranch from './views/RestaurantsHome_Multibranch';
import MyProvider from './views/MyProvider'
import { Slider, Statistic, Tabs,Result, Modal,Button,Steps, List, Affix  } from "antd";
import CurrencyFormat from "react-currency-format";
import About from './views/About';
import Promotions from './views/Promotions';
const { Step } = Steps;


const App = (props) => {

  const [restaurants_routes, setrestaurants_routes] = useState([]);
  const [restaurants_routes_multibranch, setrestaurants_routes_multibranch] = useState([]);
  const [curent_order_obj, setcurent_order_obj] = useState({});
  const [progress_model_visible, setprogress_model_visible] = useState(false);
  const [order_step_number, setorder_step_number] = useState(0);
  const [order_steps, setorder_steps] = useState(
    [
      {
        "status":"Pending",
        "key":"Pending",
        "description":"Order received"
      },
     
      {
        "status":"Delivering",
        "key":"Delivering",
        "description":"Order on delivery"
      },

    ]

  );
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);


  //is valid json
  const isValidJSON=(text)=> {
    try {
        JSON.parse(text);
        return true;
    } catch (error) {
        return false;
    }
  }



  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
        setIsModalVisible(false);
      });
    }
  };


  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setIsModalVisible(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    compo_loaded()

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };

  }, []);


  const compo_loaded=()=>{

    let form_data = new FormData();
    form_data.append("backend", serverconfig.backendserverurl);
    axios
      .post(
        serverconfig.backendserverurl + `/customqueries/get_allrestaurants`,
        form_data,
        {
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        setrestaurants_routes(JSON.parse(res.data.report))

      });


      let form_data_1 = new FormData();
      form_data_1.append("backend", serverconfig.backendserverurl);
      axios
        .post(
          serverconfig.backendserverurl + `/customqueries/get_allmultibranchrestaurants`,
          form_data_1,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          setrestaurants_routes_multibranch(JSON.parse(res.data.report))
        
         // console.log(JSON.parse(res.data.report))
  
        });      


        //check for current order running
        //get the current cut list
        if (localStorage.getItem("current_order_number")){
          let value = localStorage.getItem("current_order_number");
          if (value) {
  
                let new_val=JSON.parse(value)
    
                let form_data_2 = new FormData();
                form_data_2.append("order_number", new_val.order_number);
                form_data_2.append("restaurant", new_val.restaurant);
      
                axios
                  .post(
                    serverconfig.backendserverurl + `/customqueries/get_current_clientorder`,
                    form_data_2,
                    {
                      headers: {
                        "content-type": "multipart/form-data",
                      },
                    }
                  )
                  .then((res) => {
                    console.log(JSON.stringify(JSON.parse(res.data.report)))
      
                    setcurent_order_obj(JSON.parse(res.data.report)[0])
      
                    if (JSON.stringify(JSON.parse(res.data.report))!="[]"){
      
                      if (JSON.parse(res.data.report)[0].order_status==="Pending"){
    
                        setorder_step_number(0)
                        setprogress_model_visible(true)
        
                      }else
                    
                      if (JSON.parse(res.data.report)[0].order_status==="OrderReady"){
                        setorder_step_number(1)
                        setprogress_model_visible(true)
        
                      }else
        
                      
                      if (JSON.parse(res.data.report)[0].order_status==="Delivering"){
                        setorder_step_number(2)
                        setprogress_model_visible(true)
    
                      }else{
        
                        localStorage.removeItem('current_order_number')
                        setprogress_model_visible(false)
    
                      }
      
                    }
                 
                  });   
      
          }
        }
     

  }

 
    return (
      <Router>
         <MyProvider>

      <LoginLayout  {...props}>
     
      <Switch>
         {/*<LoginBaseRouter /> */ }

        <Route exact path='/' component={Login}  />
        <Route exact path='/about/' component={About}  />
        <Route exact path='/restaurants/' component={Restaurants}  />
        <Route exact path='/contact/' component={ContactUsForm}  />
        <Route exact path='/all_promos/' component={Promotions}  />

        {/* Dynamic restaurant routes */}
        {restaurants_routes.map((restaurant, index) => (
          <Route  key={index} path={"/"+restaurant.online_order_link}>
            <RestaurantHome restaurant={restaurant} />
          </Route>
        ))}


       {/**multi batnc */} 
      {restaurants_routes_multibranch.map((restaurant, index) => (
          <Route key={index} path={"/"+restaurant.online_order_link}>
            <RestaurantsHome_Multibranch restaurant={restaurant} />
        
          </Route>
       ))}

      </Switch>

      </LoginLayout> 


       {
        progress_model_visible===true && JSON.stringify(curent_order_obj)!="{}"?
        <Modal
        visible={progress_model_visible}
        title="Order progress tracker"
        onCancel={() => {
          setprogress_model_visible(false)
        }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setprogress_model_visible(false)
            }}
          >
            close
          </Button>,
        ]}
      >

        <h6 style={{display:'flex',justifyContent:'center'}}>{curent_order_obj.restaurant_name}</h6>
        <h5 style={{display:'flex',justifyContent:'center',fontWeight:'bolder'}}>
          UGX
          <CurrencyFormat
            value={Number(curent_order_obj.order_total)+Number(curent_order_obj.delivery_charges)}
            displayType={"text"}
            thousandSeparator={true}
          />
          </h5>
          <h6 style={{display:'flex',justifyContent:'center'}}>Order ID: {curent_order_obj.ordernumber}</h6>

        <Steps current={order_step_number} /* Assuming you want to highlight the first step initially */>
          {order_steps.map((step, index) => (
            <Step key={step.status} title={step.status} description={''} />
          ))}
        </Steps>
      
        </Modal>

        :
        null
       }




      <Modal
        title="Install eats.biz App"
        visible={isModalVisible}
        onOk={handleInstallClick}
        onCancel={()=>{
          setIsModalVisible(false);

        }}
        okText="Install"
        cancelText="Cancel"
      >
        <p>Install the eats.biz App, order your favorite food, and have it delivered fast.</p>
      </Modal>

      </MyProvider>
      </Router>

    );
 
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.token !== null
  }
}

const mapDispatchToProps = dispatch =>{
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(App);
