import React from 'react';
import { Form, Input, Button,Spin,Select,DatePicker,Image,Switch, Card, message } from 'antd';
import axios from 'axios'
import * as serverconfig from './serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import MessageBox3 from './MessageCard3';

var CryptoJS = require("crypto-js");
const dateFormat = 'DD/MM/YYYY';

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var username=''

const { TextArea } = Input;

class ContactUsForm extends React.Component {

  state = {
    email:'' ,  
    message:'',
    name:'',
    phone:'',
    datarequested:false,
  }


  componentDidMount(){

    

  }



  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('email', this.state.email);
    form_data.append('message', this.state.message);
    form_data.append('phone', this.state.phone);
    form_data.append('name', this.state.name);

    axios.post(serverconfig.backendserverurl+'/customqueries/send_messagenote', form_data, {
      emailers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res =>{
    message.info(res.data.message)
    this.setState({datarequested:false})
    window.location.reload(false)
  }
  )
  .catch(error => console.log(error))

  }
  
  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
      
    }else{
      return (
        <div style={{display:'flex',alignSelf:'center',justifyContent:'center',flexDirection:'column'}}>

          <Card>
          <h3 style={{display:'flex',justifyContent:'center',alignSelf:'center',fontWeight:'bolder'}}>Contact Us</h3>

          <Form
          onFinish={(event) => this.handleFormSubmit(event)}
          >

      <FormItem label="Name"
        name="name"
        rules={[
          {
            required: true,
            message: 'Please input name',
          },
        ]}
      >
        <Input name="name" 
         placeholder=""
         value={this.state.name} 
         onChange={(val)=>{this.setState({name:val.target.value})}} />
      </FormItem>



      <FormItem label="Email"
        name="email"
        rules={[
          {
            required: true,
            message: 'Please input email',
          },
        ]}
      >
        <Input name="email" 
         placeholder=""
         value={this.state.email} 
         type="email" 
         onChange={(val)=>{this.setState({email:val.target.value})}} />
      </FormItem>


      <FormItem label="Phone"
        name="phone"
        rules={[
          {
            required: true,
            message: 'Please input phone',
          },
        ]}
      >
        <Input name="phone" 
         placeholder=""
         value={this.state.phone} 
         onChange={(val)=>{this.setState({phone:val.target.value})}}
         type='number'
         />
      </FormItem>

      <FormItem label="Message"
        name="message"
        rules={[
          {
            required: true,
            message: 'Please input message',
          },
        ]}
      >
        <TextArea 
         name="message" 
         placeholder=""
         value={this.state.message} 
         onChange={(val)=>{this.setState({message:val.target.value})}}
         autoSize={{ minRows: 2, maxRows: 6 }}
         />
      </FormItem>

      <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end'}}>
          <FormItem>
              <Button  type="primary" htmlType="submit">Send</Button>
         </FormItem>

      </div>
      </Form>


         </Card>
        </div>
      );
    }

  }

}


export default ContactUsForm;



